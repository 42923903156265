<template>
  <div>
    <ModuleComponent :module-data="moduleData" @changed="item = $event">
      <template v-slot:customColumn_active="{ rowItem }">
        <TableStatusColumn v-model="rowItem.active"></TableStatusColumn>
      </template>
      <template v-slot:tableFilters="{ filters }">
        <b-container fluid>
          <b-row>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.NAME')">
                <b-form-input
                  v-model="filters.name.value"
                  :placeholder="t('GLOBAL.NAME')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.CODE')">
                <b-form-input
                  v-model="filters.code.value"
                  :placeholder="t('GLOBAL.CODE')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="6" md="3">
              <b-form-group :label="t('GLOBAL.STATUS')" class="mb-3">
                <vue-select
                  v-model="filters.active.value"
                  label="text"
                  :options="[
                    { text: t('GLOBAL.ACTIVE'), value: true },
                    { text: t('GLOBAL.PASSIVE'), value: false }
                  ]"
                  :multiple="false"
                  :placeholder="t('GLOBAL.ALL')"
                >
                </vue-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template v-slot:form="{ item }">
        <b-row>
          <b-col cols="12" sm="8" md="8">
            <b-form-group :label="t('GLOBAL.NAME')">
              <b-form-input
                name="name"
                v-model="item.name"
                :placeholder="t('GLOBAL.NAME')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="4" md="4">
            <b-form-group :label="t('GLOBAL.CODE')">
              <b-form-input
                name="code"
                v-model="item.code"
                :placeholder="t('GLOBAL.CODE')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.BIO')">
              <TinyEditor v-model="item.bio" />
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.DESCRIPTION')">
              <TinyEditor v-model="item.description" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <Upload v-model="item.image" single :title="t('GLOBAL.IMAGE')" />
          </b-col>
          <b-col cols="2" sm="3" md="2">
            <b-form-group :label="t('GLOBAL.STATUS')">
              <b-form-checkbox v-model="item.active" switch>
                {{ t("GLOBAL.ACTIVE") }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="2" sm="3" md="2">
            <b-form-checkbox v-model="item.jan" switch>
              {{ $t("GLOBAL.MONTHS.JANUARY") }}
            </b-form-checkbox>
            <b-form-checkbox v-model="item.feb" switch>
              {{ $t("GLOBAL.MONTHS.FEBRUARY") }}
            </b-form-checkbox>
            <b-form-checkbox v-model="item.mar" switch>
              {{ $t("GLOBAL.MONTHS.MARCH") }}
            </b-form-checkbox>
            <b-form-checkbox v-model="item.apr" switch>
              {{ $t("GLOBAL.MONTHS.APRIL") }}
            </b-form-checkbox>
            <b-form-checkbox v-model="item.may" switch>
              {{ $t("GLOBAL.MONTHS.MAY") }}
            </b-form-checkbox>
            <b-form-checkbox v-model="item.jun" switch>
              {{ $t("GLOBAL.MONTHS.JUNE") }}
            </b-form-checkbox>
            <b-form-checkbox v-model="item.jul" switch>
              {{ $t("GLOBAL.MONTHS.JULY") }}
            </b-form-checkbox>
            <b-form-checkbox v-model="item.aug" switch>
              {{ $t("GLOBAL.MONTHS.AUGUST") }}
            </b-form-checkbox>
            <b-form-checkbox v-model="item.sep" switch>
              {{ $t("GLOBAL.MONTHS.SEPTEMBER") }}
            </b-form-checkbox>
            <b-form-checkbox v-model="item.oct" switch>
              {{ $t("GLOBAL.MONTHS.OCTOBER") }}
            </b-form-checkbox>
            <b-form-checkbox v-model="item.nov" switch>
              {{ $t("GLOBAL.MONTHS.NOVEMBER") }}
            </b-form-checkbox>
            <b-form-checkbox v-model="item.dec" switch>
              {{ $t("GLOBAL.MONTHS.DECEMBER") }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </template>
    </ModuleComponent>
  </div>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-Product
export default {
  name: "products",
  data() {
    return {
      item: {},
      moduleData: {
        name: "products",
        primaryKey: "id",
        table: {
          sortBy: "name",
          sortDesc: false,
          graphqlQuery: `
							id,name,code,active
						`,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.NAME"), value: "name" },
            { text: this.t("GLOBAL.CODE"), value: "code" },
            {
              text: this.t("GLOBAL.STATUS"),
              value: "active",
              width: "60",
              align: "center"
            }
          ],
          customColumns: ["active"],
          filters: {
            name: {
              type: "string",
              searchBy: "contains",
              value: null
            },
            code: {
              type: "string",
              searchBy: "contains",
              value: null
            },
            active: {
              type: "bool",
              value: null
            }
          }
        }
      }
    };
  }
};
</script>
